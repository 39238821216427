export function lastWord(){
    $(".last-word")
      .html(function () {
    let text = $(this).text().split(" ");
    if (text.length > 1) {
      let count = text.length - 3;
      let last = text.splice(count, 3);
      
      last = String(last);
      //last = last.replace(/\s/g, '');
      last = last.split(',').join(' ');
      return `${text.join(" ")} <span>${last}</span>`;
    } else {
      return `${text}`;
    }
  });
}