import $ from "jquery";

export function accordionProduct() {
    const product = document.querySelectorAll('.product');
    product.forEach(item => {
        const title = item.querySelector('.accordion_title');
        const text = item.querySelector('.accordion_text');
        $('.accordion_text').slideUp();
        title.addEventListener('click', function(){
            if($(title).hasClass('active')){
                title.classList.remove('active');
                $(title).parents('.unwrap').removeClass('active');
                (this).innerHTML = 'Развернуть описание и комплектацию'
                $(text).slideUp();
            }else{
                title.classList.add('active');
                $(title).parents('.unwrap').addClass('active');
                (this).innerHTML = 'Свернуть'
                $(text).slideDown();
            }
        });
    });  
}
export function accordionFaq() {
   
    
    $('.faq_title').click(function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this)
                .parents('.faq_item')
                .find('.faq_text')
                .slideUp();
        } else {
            $('.faq_title').removeClass('active');
            $(this).addClass('active');
            $('.faq_text').slideUp();
            $(this)
                .parents('.faq_item')
                .find('.faq_text')
                .slideDown();
        }
    });
}