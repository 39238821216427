import $ from "jquery";
export function number() {
    const parent = document.querySelectorAll('.calc');
    parent.forEach(item => {
        function addNumber(minus, plus, value, html){
            const buttonMinus = item.querySelector(minus);
            const buttonPlus = item.querySelector(plus);
            const valueInput = item.querySelector(value);
            const htmlValue = item.querySelector(html);
                buttonMinus.addEventListener('click', function(){
                    if(valueInput.value < 2){
                        return;
                    }else{
                        valueInput.value --; 
                        htmlValue.innerHTML = valueInput.value;
                    } 
                })
            
            
            buttonPlus.addEventListener('click', function(){
                valueInput.value ++;
                htmlValue.innerHTML = valueInput.value;
            })
            htmlValue.innerHTML = valueInput.value;
        }
        addNumber('.mount-minus', '.mount-plus', '.value-input-mount', '.number-mount');
        addNumber('.quant-minus', '.quant-plus', '.value-input-quant', '.number-quant');
    });
    
}