import Swiper from "swiper/swiper-bundle";
export function productSlider(){
  const parent = document.querySelectorAll('.product__slider');
  parent.forEach(item =>{
    const tumbClass = item.querySelector('.thumb');
    const sliderClass = item.querySelector('.slider');
    const thumb = new Swiper( tumbClass, {
      loop: true,
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesProgress: true,
    });
    const slider = new Swiper(sliderClass, {
      loop: true,
      spaceBetween: 10,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      thumbs: {
        swiper: thumb,
      },
    });
  });

}
export function mapSlider(){
  const parent = document.querySelectorAll('.sliderMap');
  parent.forEach(item => {
    const swiper = new Swiper(item, {
      pagination: {
        el: ".pagination",
        clickable: true,
      },
    });
  });
}
export function certificatesSlider(){
  const swiper = new Swiper('.sliderCertificates', {
    slidesPerView: 2,
    spaceBetween: 48,
    pagination: {
      el: ".pagination",
      clickable: true,
     },
     navigation: {
      nextEl: ".certificates__slider-arrow--right",
      prevEl: ".certificates__slider-arrow--left",
    },
     breakpoints: {
      420: {
        slidesPerView: 3,
      },
      790: {
        slidesPerView: 4,
      }
  }
  });
}