import $ from "jquery";
import svg4everybody from "svg4everybody";
import baguetteBox from "baguettebox.js";

import {
    mobileMenu,
} from "./modules/mobileMenu";

import {
    productSlider,
    mapSlider,
    certificatesSlider,
} from "./modules/sliders";

import {
    accordionProduct,
    accordionFaq,
} from "./modules/accordion";

import {
    select,
} from "./modules/select";

import {
    number,
} from "./modules/number";

import {
    calcAdd,
} from "./modules/calc";

import {
    lastWord,
} from "./modules/lastWord";

$(function () {
    mobileMenu();
    productSlider();
    mapSlider();
    certificatesSlider();
    accordionProduct();
    accordionFaq();
    select();
    number();
    calcAdd();
    lastWord();
});