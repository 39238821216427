
export function calcAdd() {
   const parent = document.querySelectorAll('.calc');
   const buttonAdd = document.querySelector('.add');
   let price = 0
   function checkInputs(){
      parent.forEach(item => {
         const select = item.querySelectorAll('.select__item-s');
         const priceHtml = item.querySelector('.price');
         select.forEach(selectItem => {
            const input = selectItem.querySelector('input');
            if(input.checked){
               price = input.dataset.price;
            }
         });
         priceHtml.innerHTML = price;
      });
   }
   checkInputs()
   document.addEventListener('input', checkInputs);
   let index = 0;
   parent[index].classList.add('active');
   buttonAdd.addEventListener('click', function(){
      if(index >= parent.length - 1){
         buttonAdd.disabled = true;
         return;
      }
      console.log(index);
      index++
      parent[index].classList.add('active');
   });

}
